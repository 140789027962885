import request from '@/utils/request'

// 信息
const info = (platform, version = '') => {
  const data = {
    platform,
    version
  }
  return request.get('app/version/info', data)
}

export default {
  info
}
