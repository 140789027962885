/* 客户端模块 */
import Layout from '@/layout/empty'

const router = {
  path: '/app',
  component: Layout,
  children: [{
    path: 'download',
    component: () => import('@/views/app/download')
  }]
}

export default router
