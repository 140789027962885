import Vue from 'vue'
import Router from 'vue-router'
import Layout from '@/layout'

Vue.use(Router)

import appRouter from './app'
import articleRouter from './article'

const routes = [{
  path: '/',
  component: Layout,
  redirect: '/index',
  children: [{
      path: 'index',
      component: () => import('@/views/index')
    }
  ]
  },
  appRouter,
  articleRouter
]

const router = new Router({
  mode: 'history',
  routes
})

export default router
